$black: black;

$grey-darker: hsl(221, 14%, 10%);
$grey-dark: hsl(221, 14%, 20%);
$grey: hsl(221, 14%, 40%);
$grey-light: hsl(221, 14%, 80%);
$grey-lighter: hsl(221, 14%, 90%);
$grey-lightest: hsl(221, 14%, 97%);

$orange: #bb3e03;
$yellow: #ee9b00;
$green: #386641;
$turquoise: #94d2bd;
$cyan: #0a9396;
$blue: #00509d;
$purple: #7209b7;
$red: #ae2012;

$dark-blue: #005f73;
$very-dark-blue: #03045e;
$text: #000;

$rainbow: (
	rgb(229, 0, 0),
	rgb(255, 141, 0),
	rgb(255, 238, 0),
	rgb(2, 129, 33),
	rgb(0, 76, 255),
	rgb(119, 0, 136)
);

$primary: $blue;