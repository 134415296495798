@use "colors.scss" as c;
@use "../node_modules/bulma/sass/utilities/mixins";

$navbar-height: 3.25rem !default;

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.width-is-128 {
  width: 128px;
  height: auto;
}

.width-is-50p {
  width: 50%;
}

.width-is-80p {
  width: 80%;
}

.is-borderless {
  border: none !important;
}

.no-list-style {
  list-style: none !important;
}

:target {
  scroll-margin-top: $navbar-height;
}

#back-to-top {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 25px;
  right: 25px;
  transition: all 0.8s ease;
  opacity: 0;
  visibility: hidden;
  z-index: 5;

  img {
    height: 40px;
    margin: 5px auto 5px auto;
  }

  &:hover {
    cursor: pointer;
    transform: rotateY(180deg);
  }

  &.show {
    opacity: 1;
    visibility: visible;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
}

.line-numbers {
  padding-right: 10px;
}

.enby {
  background: linear-gradient(
    yellow 25%,
    white 0 50%,
    mediumorchid 0 75%,
    black 0);
}

.rainbow {
  @for $i from 1 through 6 {
    &:nth-child(6n+#{$i}) {
      $rainbowcolor: nth(c.$rainbow, $i);

      .rainbow-dark {
        background: rgba($rainbowcolor, 0.55) !important;
        color: black !important;
      }

      .rainbow-light {
        background: rgba($rainbowcolor, 0.2) !important;
        color: black !important;
      }
    }
  }
}

#maintitle {
  background: linear-gradient(to right, rgb(237, 34, 36), rgb(243, 91, 34), rgb(249, 150, 33), rgb(245, 193, 30), rgb(241, 235, 27) 27%, rgb(241, 235, 27), rgb(241, 235, 27) 33%, rgb(99, 199, 32), rgb(12, 155, 73), rgb(33, 135, 141), rgb(57, 84, 165), rgb(97, 55, 155), rgb(147, 40, 142));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-variant: small-caps;
  font-weight: bold;
  -webkit-text-stroke: 0.5px black;
  font-size: 3rem;
}

@include mixins.widescreen {
  #maintitle {
    letter-spacing: 2rem;
    word-spacing: 2rem;
  }
}

@include mixins.until-widescreen {
  #maintitle {
    letter-spacing: 0.5rem;
    word-spacing: 0.5rem;
    font-size: 2rem;
  }
}

.quote {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;

  @for $i from 1 through 6 {
    &:nth-of-type(6n+#{$i}) {
      $color: nth(c.$rainbow, $i);
      border-left: 8px double rgba($color, 0.65) !important;
      border-right: 1px solid rgba($color, 0.4);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  .quote-text {
    padding-bottom: 15px;

    &:before {
      content: "»";
    }
    
    &:after {
      content: "«";
    }
  }

  .quote-attribution {
    font-size: .8rem;
    text-align: right;
  }
}

.has-hidden-footnotes {
  .footnotes,
  .footnote {
    display: none !important;
  }
}

.is-small-caps {
  font-variant: small-caps;
}

a.footnote {
  margin-left: 2px;

  &::before {
    content: "→";
  }
}

.emoji {
  height: 1.25rem;
  vertical-align: middle;
}

.leaflet-map {
  height: 40rem !important;
  z-index: 0;
}

.language-mermaid {
	strong {
		font-weight: 900;
	}

	p, em, .edgeLabel, .nodeLabel {
		font-weight: 100;
	}
}

pre:has(.language-mermaid) {
  background: white !important;
}

.content figure.highlight {
	pre {
		background-color: rgba(
			c.$grey-lighter,
			0.25
		);
	}

	margin: 1em 0 1em 0;
	text-align: left;
}

.post {
  .post-meta {
    font-size: 0.9rem !important;
  }

  @include mixins.desktop {
    &:first-child {
      border-right: 1px solid c.$grey;
      padding-right: 3rem;
    }

    &:nth-child(2) {
      padding-left: 3rem;
    }
  }
}