@use "colors" as c;

$radius: 4px;

$navbar-item-hover-color: c.$primary;
$navbar-item-active-color: c.$primary;
$navbar-dropdown-item-hover-color: c.$primary;
$navbar-dropdown-item-active-color: c.$primary;

$control-height: 2.5em;
$footer-padding: 0rem 1.5rem;

$text: c.$black;
$primary: c.$primary;

$navbar-height: 3.25rem;

:root {
    --bulma-text-l: c.$black;
    --bulma-body-color: c.$black;
    --bulma-strong-color: c.$black;
    --bulma-weight-light: 400;
    --bulma-card-content-padding: 1rem;
}

.sortable {
    --th-color: #000;
    --th-bg: $table-head-background-color;
    --td-color: #000;
    --td-on-stripe-color: $table-body-background-color;
    --stripe-color: $table-body-background-color;
}

::selection {
    color: $text;
    background: $primary;
}